import React from 'react';

const QuienesSomos = () => (
<section className="quienes-somos-section">
  <iframe src="https://player.vimeo.com/video/151462931" width="500" height="375" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
  <h2 className="section-title">Quiénes Somos</h2>
  <p className="section-text">Somos un grupo de profesionales con experiencia en edición, diseño editorial y diseño interactivo, que decidimos formar BUMBA Editorial para llevar al público libros nuevos o adaptados a un formato interactivo y animado.
Para nosotros el contenido será siempre lo más importante. Vemos en los nuevos formatos, una oportunidad más de llevar a chicos y grandes a otros mundos, experiencias y perspectivas.</p>
</section>
)

export default QuienesSomos;
