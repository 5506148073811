import React from 'react';
import Header from '../components/Header.js';
import Head from '../components/head'
import QuienesSomos from '../components/QuienesSomos.js';


export default () => (
    <div className="App">
      <div className="main-container">
      <Head title="Home"/>
      <Header />
      <QuienesSomos />
      <footer>- Copyright BUMBA Editorial -</footer>
      </div>
    </div>
)
